import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

import parameters from './__generated__/dialogStandardTrialLimitBreakersQuery.graphql';

type EntryPointParams = {
	cloudId: string;
	hamsProductKey: string;
	shouldIncludeIfProductIsConfluence: boolean;
	shouldIncludeIfProductIsJiraSoftware: boolean;
};

export const entryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "@atlaskit-internal_edition-awareness-standard-trial-limit-breakers-dialog" */ './dialog'
			),
		{
			moduleId: 'edition-awareness-standard-trial-limit-breakers-dialog',
		},
	),
	getPreloadProps: ({
		cloudId,
		hamsProductKey,
		shouldIncludeIfProductIsConfluence,
		shouldIncludeIfProductIsJiraSoftware,
	}: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			tenantContexts: {
				parameters,
				variables: {
					cloudId,
					hamsProductKey,
					shouldIncludeIfProductIsConfluence,
					shouldIncludeIfProductIsJiraSoftware,
				},
			},
		},
	}),
});
