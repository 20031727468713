/**
 * @generated SignedSource<<bce0d8edfb037dcecbbea87eaf0c4e76>>
 * @relayHash c3fd1fe5ab32e9b89f12206690aa4565
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID eb5e883075b60b91161affba22c1d5d1e9e64e7cc0b24aa7bb096f5caa4d67ac

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type dialogStandardTrialLimitBreakersQuery$variables = {
  cloudId: string;
  hamsProductKey: string;
  shouldIncludeIfProductIsConfluence: boolean;
  shouldIncludeIfProductIsJiraSoftware: boolean;
};
export type dialogStandardTrialLimitBreakersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"limitBreakersModal_AtlassianEditionAwareness">;
};
export type dialogStandardTrialLimitBreakersQuery = {
  response: dialogStandardTrialLimitBreakersQuery$data;
  variables: dialogStandardTrialLimitBreakersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hamsProductKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldIncludeIfProductIsConfluence"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldIncludeIfProductIsJiraSoftware"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "Variable",
  "name": "hamsProductKey",
  "variableName": "hamsProductKey"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "name": "experienceUrl"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = [
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "dialogStandardTrialLimitBreakersQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "shouldIncludeIfProductIsConfluence",
            "variableName": "shouldIncludeIfProductIsConfluence"
          },
          {
            "kind": "Variable",
            "name": "shouldIncludeIfProductIsJiraSoftware",
            "variableName": "shouldIncludeIfProductIsJiraSoftware"
          }
        ],
        "kind": "FragmentSpread",
        "name": "limitBreakersModal_AtlassianEditionAwareness"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dialogStandardTrialLimitBreakersQuery",
    "selections": [
      {
        "args": [
          {
            "items": [
              {
                "kind": "Variable",
                "name": "cloudIds.0",
                "variableName": "cloudId"
              }
            ],
            "kind": "ListValue",
            "name": "cloudIds"
          }
        ],
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": [
              (v2/*: any*/)
            ],
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "inTrialOrPreDunning": true
                    }
                  }
                ],
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "experienceCapabilities",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "configurePaymentV2",
                                    "plural": false,
                                    "selections": (v4/*: any*/)
                                  }
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v6/*: any*/),
                                "type": "CcpInvoiceGroup"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v6/*: any*/),
                        "type": "CcpSubscription"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "trial",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "endTimestamp"
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "chargeDetails",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "chargeQuantities",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "quantity"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "chargeElement"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "experienceCapabilities",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "addPaymentMethodV2",
                            "plural": false,
                            "selections": (v4/*: any*/)
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v6/*: any*/),
                        "type": "CcpTransactionAccount"
                      }
                    ]
                  },
                  (v5/*: any*/)
                ],
                "storageKey": "entitlement(where:{\"inTrialOrPreDunning\":true})"
              }
            ]
          }
        ]
      },
      {
        "condition": "shouldIncludeIfProductIsConfluence",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "ConfluenceStorage",
            "kind": "LinkedField",
            "name": "confluence_storage",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "bytesUsed"
              }
            ]
          }
        ]
      },
      {
        "condition": "shouldIncludeIfProductIsJiraSoftware",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "applicationKey",
                    "value": "JIRA_SOFTWARE"
                  },
                  (v1/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "attachmentStorageUsed"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "eb5e883075b60b91161affba22c1d5d1e9e64e7cc0b24aa7bb096f5caa4d67ac",
    "metadata": {},
    "name": "dialogStandardTrialLimitBreakersQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c6ff05acc47f148d7bebcd6210137fb0";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
