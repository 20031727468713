import React, { useMemo } from 'react';

import { useOnDismissDialog } from '../../../analytics/use-on-dismiss-dialog';
import { useEditionAwareness } from '../../../services/edition-awareness-context';
import { getHamsKey } from '../../../services/product-to-hams-key';
import { ModalEntryPoint } from '../shared/modal-entry-point';

import { DIALOG_CONFIG } from './dialog-config';
import { entryPoint } from './entrypoint';

export function StandardTrialLimitBreakersDialog() {
	const { product, cloudId } = useEditionAwareness();

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			hamsProductKey: getHamsKey(product),
			shouldIncludeIfProductIsConfluence: product === 'confluence',
			shouldIncludeIfProductIsJiraSoftware: product === 'jira',
		}),
		[cloudId, product],
	);

	const onClose = useOnDismissDialog({ ...DIALOG_CONFIG });
	const entryPointProps = useMemo(() => ({ onClose }), [onClose]);

	return (
		<ModalEntryPoint
			entryPoint={entryPoint}
			entryPointProps={entryPointProps}
			entryPointParams={entryPointParams}
		/>
	);
}
